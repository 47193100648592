import React from "react"
import PropTypes from "prop-types"
import Logo from "../logo/logo"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: this.props.navItems,
      socialItems: this.props.socialItems
    };
  }

  render() {
    return (
      <footer className="py-10 overflow-hidden md:py-16">
        <div className="container">
          <nav aria-labelledby="footermenulabel" className="hidden mb-16 lg:block">
            <span id="footermenulabel" className="sr-only">Footer Menu</span>
            <ul className="block">
              {this.state.navItems.map(({ title, url }, index) =>
                <li key={index} className="inline-block mr-10">
                  <Link to={`${url}/`} activeClassName="is--active" className="text-lg link text-primary">{title}</Link>
                </li>
              )}
              <li className="inline-block mr-10">
                <Link to={"/digital-marketing-agency"} activeClassName="is--active" className="text-lg link text-primary">Locations</Link>
              </li>

              <li className="inline-block mr-10">
                <Link to={"/sectors"} activeClassName="is--active" className="text-lg link text-primary">Sectors</Link>
              </li>

              <li className="inline-block mr-10">
                <Link to={"/careers/"} activeClassName="is--active" className="text-lg link text-primary">Careers</Link>
              </li>
            </ul>
          </nav>

          <div className="flex flex-wrap mb-4 md:items-center lg:-mx-8 justify-between">
            <div className="order-2 w-full lg:w-5/12 lg:px-8 lg:order-1">
              <div className="w-12 mb-5">
                <Logo modifier={`small`} />
              </div>

              <span className="text-sm text-grey-dark md:text-lg">Adtrak is a registered company in England.<br/>&copy; Adtrak 2024. All Rights Reserved.</span>

              <div className="mt-2">
                <Link to="/privacy-policy/" className="block text-sm underline text-grey-dark md:text-base hover:text-secondary-dark focus:text-secondary-dark">Privacy Policy</Link>
                <Link to="/bullying-harassment-sexual-harassment-policy/" className="block text-sm underline text-grey-dark md:text-base hover:text-secondary-dark focus:text-secondary-dark">Bullying Harassment and Sexual Harassment Policy</Link>
                <Link to="/sitemap/" className="block text-sm underline text-grey-dark md:text-base hover:text-secondary-dark focus:text-secondary-dark">Sitemap</Link>
              </div>
            </div>

            <div className="order-1 w-full mb-10 lg:w-7/12 lg:px-8 lg:mb-0 lg:order-2 flex lg:justify-end">
              <div className="flex flex-wrap md:-mx-3 max-w-lg">

                <div className="w-full mb-6 md:w-full md:px-3 md:mb-0">
                  <div className="block text-secondary-dark">
                    <div className="pb-2 mb-2 border-b-2 outline-none border-grey">
                      <Link activeClassName="is--active" className="text-lg link text-primary" to="https://www.google.com/maps/place/Adtrak/@52.9538676,-1.156507,17z/data=!3m1!4b1!4m5!3m4!1s0x4879c22aaa06ec43:0xec466c57bd5811ae!8m2!3d52.9538676!4d-1.1543183"><FontAwesomeIcon icon="map-marker" /> Find us on Google Maps</Link>
                    </div>
                    <span>Adtrak,</span>
                    <div>
                      <span className="block">
                        Standard Court, <br/>
                        Park Row,
                      </span>
                      <span className="block">Nottingham,</span>
                      <span className="block">NG1 6GN</span>
                    </div>
                  </div>
                </div>
                
                <div className="w-full mb-6 overflow-hidden md:px-3 md:mb-0">
                  <div className="flex flex-wrap mb-3 -mx-3">
                    {this.state.socialItems.map(({ title, url, icon }, index) =>
                      <a href={url} target="_blank" rel="noopener noreferrer" className="px-3 text-lg transition duration-500 ease-in-out text-secondary-dark hover:text-secondary focus:text-secondary" aria-label={`Navigate to ${title}`} key={index}>
                        <FontAwesomeIcon icon={['fab', icon]} />
                      </a>
                    )}
                  </div>
                  
                  <address className="not-italic">
                    <div>
                      <a href="tel:01159598900" className="inline-block text-secondary-dark hover:text-secondary focus:text-secondary contactlink">0115 959 8900</a>
                    </div>
                    <div>
                      <a href="mailto:hello@adtrak.co.uk" className="inline-block text-secondary-dark hover:text-secondary focus:text-secondary contactlink">hello@adtrak.co.uk</a>
                    </div>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  navItems: PropTypes.array,
  socialItems: PropTypes.array
}

Footer.defaultProps = {
  navItems: [],
  socialItems: []
}

export default Footer